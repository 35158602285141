var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"context"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo","src":require("@/assets/code.png")}}),_c('div',{staticClass:"title"},[_c('a',{staticClass:"back",on:{"click":function($event){return _vm.$router.push('/user/login')}}},[_vm._v("返回")]),_c('h2',[_vm._v("新用户注册")])]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Username',
          {
            initialValue: _vm.defaultusername,
            rules: [
              { required: true, message: '请输入用户名!' },
              { required: true, min: 6, message: '不能小于5位' } ],
          } ]),expression:"[\n          'Username',\n          {\n            initialValue: defaultusername,\n            rules: [\n              { required: true, message: '请输入用户名!' },\n              { required: true, min: 6, message: '不能小于5位' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'Password',
          {
            initialValue: _vm.defaultpasswd,
            rules: [
              { required: true, message: '请输入用户名!' },
              { required: true, min: 6, message: '不能小于5位' } ],
          } ]),expression:"[\n          'Password',\n          {\n            initialValue: defaultpasswd,\n            rules: [\n              { required: true, message: '请输入用户名!' },\n              { required: true, min: 6, message: '不能小于5位' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Password","type":"password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"html-type":"submit","type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 注册 ")])],1)],1),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" Copyright © 王海涛19052164. "),_c('div',[_c('a',[_vm._v("Terms of Service")])])])}]

export { render, staticRenderFns }